import { Button, Checkbox, Col, Form, Input, Image, Progress, Row, Typography } from "antd";
import React from "react";
import RecordView from "../../components/common/videoRecorder";
import { getPublicFileFromServerUrl } from "../../utilities/generalUtility";
import { getActualPath } from "../common/formItems/CustomImageUpload";
import ResLogo from "../../assets/images/Megilla_logo.png";

const { Link, Text } = Typography;

const ResponseForm = ({ onFinish, data, loading, progressBarColor, progress }) => {
  const showBranding = data.organization.isMegillaBrandingEnabled;

  const termsAndConditionDownloadLink = data.organization.pdfUrl
  ? getActualPath("/upload-images/pdf/") + data.organization.pdfUrl
  : getPublicFileFromServerUrl("Megilla - Terms of Service.pdf");
  
  const privacyPolicyDownloadLink = data.organization.privacyPolicyPdfUrl
  ? getActualPath("/upload-images/pdf/") + data.organization.privacyPolicyPdfUrl
  : getPublicFileFromServerUrl("Megilla - Privacy Policy.pdf");
  return (
    <>
      {/* <VideoResponse /> */}

      <Form layout="vertical" onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div className="record-video-wight">
              <Form.Item
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Please record an answer.",
                  },
                ]}
              >
                <RecordView recordingDuration={(+data.time + 1) * 1000} portrait={data?.orientationPortrait} />
              </Form.Item>
            </div>
          </Col>

          <Col xs={24} sm={12} lg={24} xl={12}>
            <Form.Item
              name="name"
              label="Name"
              className="megilla-input-submit-response"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input
                placeholder="Enter Name"
                className="megilla-input megilla-input-submit-response"
                disabled={loading}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} lg={24} xl={12}>
            <Form.Item
              name="email"
              label="Email"
              className="megilla-input-submit-response-email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input
                placeholder="Enter Email"
                className="megilla-input megilla-input-submit-response-email"
                disabled={loading}
              />
            </Form.Item>
          </Col>

          <Form.List name="customFields">
            {() =>
              data.customFields.map((el, index) => (
                <Col xs={24} sm={12} lg={24} xl={12}>
                  <Form.Item name={[index, "id"]} hidden initialValue={el.id}>
                    <Input className="megilla-input" disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    name={[index, "answer"]}
                    label={el.statement}
                    rules={[
                      {
                        required: el.isRequired,
                        message: "Please answer this question",
                      },
                    ]}
                  >
                    <Input className="megilla-input" disabled={loading} />
                  </Form.Item>
                </Col>
              ))
            }
          </Form.List>
        </Row>
        <Row gutter={[16, 16]} className="sr-chackbox">
          <Col xs={24} sm={12} lg={24} xl={12}>
            <Form.Item
              className="mb-0"
              name="termsandconditions"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error("Please accept terms and conditions.")),
                },
              ]}
            >
              <Checkbox className="terms-condition-link" disabled={loading}>
                I agree to{" "}
                <Link href={termsAndConditionDownloadLink} target="_blank">
                  Terms & Conditions{" "}
                </Link>
                and{" "}
                <Link href={privacyPolicyDownloadLink} target="_blank">
                  Privacy Policy
                </Link>
              </Checkbox>
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error("Please accept agreement.")),
                },
              ]}
            >
              <Checkbox disabled={loading}>I allow the organization to use my video for public view</Checkbox>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} lg={24} xl={12}>
            <Form.Item className="mb-0">
              <Button block size="large" type="primary" htmlType="submit" className="sr-submit-btn" loading={loading}>
                Submit
              </Button>
            </Form.Item>
            {loading && <Progress strokeColor={progressBarColor} percent={progress} showInfo={false} />}
          </Col>
        </Row>
      </Form>

      {showBranding && (
        <div className="mobile-copyright res-ftr d-flex w-100 flex-column align-items-center justify-content-center">
          <Text className="custom-fontFamily">Powered by</Text>
          <Link href="https://www.megilla.com" target="_blank">
            <Image src={ResLogo} preview={false} style={{ width: "70px" }} />
          </Link>
        </div>
      )}
    </>
  );
};

export default ResponseForm;
